<template>
  <div class="container container_caseList">
    <div class="navBar_m">
      <div class="leftIcon iconfont icon-fanhui" @click="back()"></div>
      <div class="navBarText_m">{{name}}</div>
    </div>
    <div class="pageTitle">{{name}}</div>
    <div class="selectWrap">
      <div class="selectItem" v-for="(item, index) in selectList" :key="index">
        <div class="label">{{ item.label }}：</div>
        <div class="tags">
          <div
            class="tag"
            :class="{ active: tagItem.id == selectList[index].currentIndex }"
            v-for="tagItem in item.children"
            :key="tagItem.id"
            @click="tagClick(index, tagItem.id)"
          >
            {{ tagItem.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="floor">
      <div class="itemBanner" v-if="banner">
        <img :src="banner"  @click="goDetail_banner(plate)" alt="" />
      </div>
      <div class="listWrap">
        <div
          class="list"
        >
          <div
            class="item item_hover"
            @click="goDetails(item.type,item.id,plate_id)"
            v-for="item in articles"
            :key="item.id"
          >
            <img class="caseImg" :src="item.image_file.obs_path" alt="" />
            <div class="caseInfo">
              <div class="intro">
                {{ item.title }}
              </div>
              <div class="tags">
                <div class="tag" v-for="tagItem in item.tags" :key="tagItem.tag_id" :style="{color:tagItem.color,borderColor:tagItem.color}">
                  {{ tagItem.name ? tagItem.name : '' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block paginationWrap">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-size="16"
        layout="total, prev, pager, next"
        :total="total"
        :small="size"
        :hide-on-single-page="single"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import '@/assets/css/global.css'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      name:'', // 标题
      size: false,
      single:true,
      currentPage4: 1,
      plate_id: 0,
      total:0,
      selectList: [
        {
          label: '分类',
          id: 0,
          currentIndex: 0,
          children: [
            { tagName: '全部', id: 0 },
          ],
          page:1
        },
        {
          label: '类型',
          id: 1,
          currentIndex: 0,
          children: [
            { name: '全部', id: 0 },
            { name: '文章', id: 1 },
            { name: '视频', id: 2 }
          ]
        },
        {
          label: '排序',
          id: 2,
          currentIndex: 0,
          children: [
            { name: '综合', id: 0 },
            { name: '最新', id: 1 },
            { name: '人气', id: 2 }
          ]
        }
      ],
      articles: [],
      plate: {},
      banner:''
    }
  },
  computed: {
    ...mapState(['mobile'])
  },
  created() {
    this.plate_id = this.$route.query.plate_id;
    this.name = this.$route.query.name;
    this.getList(this.plate_id)
  },
  mounted() {
    if (this.mobile) {
      this.size = true
      let navBar = document.getElementsByClassName('navWrap')[0]
      navBar.style.display = 'none'
    }
    // let bottom = document.getElementsByClassName('bottom')[0]
    // bottom.style.display = 'block'
  },
  methods: {
    async getList(id) {
      let that = this;
      let datas = {
        plate_id: id,
        child_id: that.selectList[0].currentIndex,
        type: that.selectList[1].currentIndex,
        sort: that.selectList[2].currentIndex,
        page: that.selectList[0].page,
        limit: 16
      }
      let { data: res } = await this.$http.get('/view-more', { params: datas })
      if (res.state == 200) {
        this.articles = res.data.articles;
        this.plate = res.data.plate;
        this.banner = res.data.plate.image_file.obs_path;
        this.selectList[0].children = res.data.child_plates;
        this.selectList[0].children.unshift({name:'全部',id:0})
        this.total = res.data.total;
        this.total<16?this.single=true:false;
      }
    },
    back() {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/zh-CN/home' })
        return false
      } else {
        this.$router.go(-1)
      }
    },
    tagClick(index, id) {
      this.selectList[index].currentIndex = id;
      this.selectList[0].page = 1;
      this.getList(this.plate_id);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.selectList[0].page = val
      this.getList(this.plate_id)
    },
    goDetails(type,id,plate_id) {
      console.log(type,id)
      if (type == 1) {
        // 类型为文章
        this.$router.push(`/details?detailsId=${id}&plate_id=${plate_id}&name=${this.name}`)
      } else if(type == 2) {
        // 类型为视频 点击对象是文章
        this.$router.push(`/video?detailsId=${id}&plate_id=${plate_id}`)
      }else if(type ==3){
          // 跳转外部链接 
          window.open(id, '_blank').location;
      }
    },
    goDetail_banner(item){
      // 板块banner的跳转详情事件
      if(item.link_form ==3){
        // 链接类型是外部链接
        window.open(item.plate_link, '_blank').location;
      }else if((item.article_type==''||item.article_type==null) && item.link_form==2){
        // 链接类型是视频
        this.$router.push(`/videoCopy?src=${item.video_file.obs_path}&name=${item.name}`)
      }else if (item.article_type.type == 1 && item.link_form==1) {
        // 链接类型是文章 但是文章的类型是文字
        this.$router.push(`/details?detailsId=${item.plate_link}&plate_id=${item.id}&name=${item.name}`)
      }else if(item.article_type.type ==2 &&item.link_form==1){
        // 链接类型是文章 但是文章是视频类型
        this.$router.push(`/video?detailsId=${item.plate_link}&plate_id=${item.id}`)
      }
    },
  }
}
</script>
<style lang="less" scoped>
body {
  background: #f7f6fb;
  width: 100%;
  height: 100%;
}
.el-pager li,
.el-pagination .btn-next,
.el-pagination .btn-prev,
.el-pagination button:disabled {
  background: none;
}

.navBar_m {
  display: none;
}
.el-pagination {
  display: flex;
  justify-content: center;
}
.paginationWrap {
  margin-top: 20px;
}
.pageTitle {
  width: 100%;
  // height: 40px;
  font-family: SourceHanSansSC-Bold;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 40px;
  letter-spacing: 0px;
  color: #333333;
  margin-top: 45px;
}
.container_caseList {
  width: 1180px;
  margin: 0 auto;
  background: #f7f6fb;
  .selectWrap {
    width: 100%;
    height: 123px;
    background-color: #ffffff;
    margin-top: 17px;
    margin-bottom: 20px;
  }

  .listWrap {
    width: 100%;
    min-height: 260px;
    height: auto;
    // background-color: #ffffff;
    margin-top: 37px;
    .list {
      widows: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .item {
        border-radius: 5px;
        height: 100%;
        width: 24%;
        // background: chartreuse;
        overflow: hidden;
        cursor: pointer;
        margin-right: 15px;
        margin-bottom: 15px;
      }
      .item:nth-child(4n) {
        margin-right: 0;
      }
      .caseImg {
        display: block;
        width: 100%;
        height: 155px;
      }
      .caseInfo {
        width: 100%;
        // height: 105px;
        background: #fff;
        padding: 15px 10px 19px 10px;
      }
      .intro {
        width: 100%;
        height: 45px;
        // margin-top: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-bottom: 10px;
      }
      .tags {
        width: 100%;
        height: 21px;
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        align-items: center;
        flex-wrap: wrap;
      }
      .tag {
        min-width:25%;
        max-width: 30%;
        line-height: 19px;
        text-align: center;
        height: 100%;
        float: left;
        padding: 0px 4px;
        font-family: SourceHanSansCN-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ff9d4e;
        border: 1px solid #ff9d4e;
        margin-right: 3px;
        box-sizing: border-box;
        border-radius: 5px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tag:nth-child(4){
        margin-right: 0;
      }
    }
  }

  .title {
    width: 100%;
    height: 46px;
    margin-top: 40px;
    font-family: SourceHanSansSC-Bold;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #333333;
  }
  .itemBanner {
    width: 100%;
    height: 274px;
    background-color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  //   筛选
  .selectItem {
    width: 100%;
    height: 40px;
    padding: 0 19px;
    .label {
      width: 5%;
      float: left;
      font-family: SourceHanSansSC-Regular;
      font-size: 16px;
      line-height: 40px;
      color: #333333;
    }
    .tags {
      width: 90%;
      float: left;
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tag {
        padding: 0 4px;
        height: 26px;
        border-radius: 2px;
        font-family: SourceHanSansSC-Regular;
        font-size: 16px;
        line-height: 26px;
        color: #333333;
        cursor: pointer;
      }
      .active {
        background-color: #ffb11a;
        color: #fff;
      }
    }
  }
}
@media screen and(max-width:768px) {
  .floor{
    padding: 0 7px;
  }
  // 隐藏顶部navBar
  // .navWrap {
  //   display: none;
  // }
  .container_caseList {
    width: 100%;
    margin: 0 auto;
    .pageTitle {
      display: none;
    }
    // 显示移动端navBar
    .navBar_m {
      display: block;
      width: 100%;
      height: 46px;
      background: #fff;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      .leftIcon {
        width: 40px;
        height: 100%;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999999;
        position: relative;
        z-index: 6;
      }
      .navBarText_m {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        font-family: SourceHanSansSC-Regular;
        font-size: 18px;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .paginationWrap {
      margin-top: 0.2rem;
    }
    .pageTitle {
      width: 100%;
      // height: 0.4rem;
      font-family: SourceHanSansSC-Bold;
      font-size: 0.3rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.4rem;
      letter-spacing: 0rem;
      color: #333333;
      margin-top: 0.45rem;
    }
    .selectWrap {
      width: 100%;
      height: auto;
      background-color: #ffffff;
      margin: 0 auto;
      padding: 5px  0;
    }

    .listWrap {
      width: 100%;
      height: auto;
      // background-color: #ffffff;
      margin-top: 0.37rem;
      .list {
        widows: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .item {
          border-radius: 0.05rem;
          height: 100%;
          width: 48%;
          // background: chartreuse;
          overflow: hidden;
          cursor: pointer;
          margin-bottom: 10px;
          margin-left: 0;
          margin-right: 0;
        }
        .item:nth-child(2n) {
          margin-left: 0.28rem;
        }
        .caseImg {
          display: block;
          width: 100%;
          // height: auto;
        }
        .caseInfo {
          width: 100%;
          height: auto;
          background: #fff;
          // padding: 0.07rem;
          padding: 0.15rem 0.1rem 0.19rem 0.1rem;
        }
        .intro {
          width: 100%;
          height: 45px;
          margin-top: 0.17rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 0.1rem;
        }
        .tags {
          width: 100%;
          height: 21px;
          justify-content: flex-start;
          overflow: hidden;
          align-items: center;
          flex-wrap: wrap;
        }
        .tag {
          min-width: 25%;
          max-width: 30%;
          padding: 0 2px;
          line-height: 18px;
          font-family: SourceHanSansCN-Regular;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #ff9d4e;
          border: 0.01rem solid #ff9d4e;
          margin-right: 0.03rem;
          box-sizing: border-box;
          border-radius: 0.05rem;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .tag:nth-child(4) {
          margin-right: 0;
        }
      }
    }

    .title {
      width: 100%;
      height: 0.46rem;
      margin-top: 0.4rem;
      font-family: SourceHanSansSC-Bold;
      font-size: 0.26rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #333333;
    }
    .itemBanner {
      width: 100%;
      height: auto;
      background-color: #ffffff;
      border-radius: 0.05rem;
      margin-top: 5px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    //   筛选
    .selectItem {
      width: 100%;
      height: 0.4rem;
      padding: 0 0.19rem;
      margin-bottom: 5px;
      .label {
        display: none;
      }
      .tags {
        width: 100%;
        float: left;
        height: 0.4rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .tag {
          padding: 0 0.04rem;
          height: auto;
          border-radius: 0.02rem;
          font-family: SourceHanSansSC-Regular;
          font-size: 0.26rem;
          line-height: 0.375rem;
          color: #333333;
          cursor: pointer;
          padding:0 10px;
          box-sizing: border-box;
        }
        .active {
          background-color: #ffb11a;
          color: #fff;
          border-radius: 12px;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  #app {
    padding-top: 46px;
  }
}
@media screen and (max-width: 320px) {
  .container_caseList{
    //   筛选
    .selectItem {
      width: 100%;
      height: 0.4rem;
      padding: 0 0.19rem;
      .label {
        display: none;
      }
      .tags {
        width: 100%;
        float: left;
        height: 0.4rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
        .tag {
          padding: 0 4px;
          height: auto;
          border-radius: 0.02rem;
          font-family: SourceHanSansSC-Regular;
          font-size: 0.26rem;
          line-height: 0.375rem;
          color: #333333;
          cursor: pointer;
          box-sizing: border-box;
        }
        .active {
          background-color: #ffb11a;
          color: #fff;
          border-radius: 12px;
        }
      }
    }
    .listWrap {
      width: 100%;
      height: auto;
      // background-color: #ffffff;
      margin-top: 0.37rem;
      .list {
        widows: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .item {
          border-radius: 0.05rem;
          height: 100%;
          width: 48%;
          // background: chartreuse;
          overflow: hidden;
          cursor: pointer;
          margin-bottom: 10px;
          margin-left: 0;
          margin-right: 0;
        }
        .item:nth-child(2n) {
          margin-left: 0.28rem;
        }
        .caseImg {
          display: block;
          width: 100%;
          // height: auto;
        }
        .caseInfo {
          width: 100%;
          height: auto;
          background: #fff;
          // padding: 0.07rem;
          padding: 0.15rem 0.1rem 0.19rem 0.1rem;
        }
        .intro {
          width: 100%;
          height: 45px;
          margin-top: 0.17rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 0.1rem;
        }
        .tags {
          width: 100%;
          height: 21px;
          display: flex;
          justify-content: start;
        }
        .tag {
          padding: 0 4px;
          font-family: SourceHanSansCN-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0rem;
          color: #ff9d4e;
          border: 0.01rem solid #ff9d4e;
          margin-right: 0.03rem;
          box-sizing: border-box;
          border-radius: 0.05rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
   
}

</style>
